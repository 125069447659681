import App from "@/App.vue";
import Bus from "@/utils/bus";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

Vue.config.productionTip = false;
Vue.config.silent = true;

let appVue;
let isSentryInitialized = false;

const loadBasicPlugins = async () => {
  let plugins;

  // Load the basic plugins
  await import("@/plugins").then((obj) => {
    plugins = obj;
  });

  return plugins;
};

const loadAdditionalPlugins = async () => {
  let plugins;

  // Load the basic plugins
  await import("@/plugins/custom").then((obj) => {
    plugins = obj;
  });

  await import("@/filters");
  await import("@/directives");

  return plugins;
};

const initApp = async (basicPlugins, additionalPlugins, loadedPlugins = false) => {
  return new Promise((resolve => {
    if (process.env.VUE_APP_SENTRY_DSN && ! isSentryInitialized) {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: [/^https:\/\/v3-develop-api.doinsport\.club/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });

      isSentryInitialized = true;
    }
    if (! loadedPlugins) {
      appVue = new Vue({
        ...basicPlugins.default,
        render: (h) => h(App),
      });
    } else {
      appVue = new Vue({
        ...basicPlugins.default,
        ...additionalPlugins.default,
        render: (h) => h(App),
      });
    }
    appVue.$mount("#app");

    resolve(loadedPlugins);
  }));
};

const loadAppVue = async () => {
  const basicPlugins = await loadBasicPlugins();
  const storedClub = JSON.parse(localStorage.getItem("current-club"));

  return storedClub ? await initApp(basicPlugins, await loadAdditionalPlugins(), true) : await initApp(basicPlugins, {}, false);
};

loadAppVue();

Bus.$on("on:current-club-loaded", async () => {
  const basicPlugins = await loadBasicPlugins();
  const additionalPlugins = await loadAdditionalPlugins();

  appVue.$destroy();

  initApp(basicPlugins, additionalPlugins, true)
    .then((loadedPlugins) => {
      if (loadedPlugins) {
        if(appVue.$router.currentRoute.name !== "planning") {
          appVue.$router.replace("/");
        }
      }
    });
});